import React, { useEffect } from "react";
import Layout from "../components/layout";
import OracleTiles from "../components/oracleTiles";
import { Helmet } from "react-helmet";
import { Container, Tabs, Tab, Col, Row } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import enrolIcon from "../assets/icons/icon-enrol.png";
import SectionHeader from "../components/sectionHeader";
import "../components/oracle.scss";
import "../components/about/ourPurpose.scss";

function Publications({ data }) {
  console.log(data);
  const PageHeader = data.allStrapiOraclePage.nodes[0].PageHeader;
  const industrySpecilizatiion =
    data.allStrapiOraclePage.nodes[0].industrySpecilizatiion;

  const oracleResources = data.allStrapiOraclePage.nodes[0].oracleResources;
  const oraclePublications =
    data.allStrapiOraclePage.nodes[0].oraclePublications;
  console.log("oraclePublications", oraclePublications);
  const oracleData = data.allStrapiOraclePage.nodes[0].oraclePublications;

  return (
    <Layout>
      <Helmet title="oracle" defer={false} />
      <div className="container">
        <div className="page-banner">
          <img
            src={data.allStrapiOraclePage.nodes[0].oracleTopBanner.publicURL}
            className="img-fluid"
          />
        </div>
      </div>
      <Container className="publications container">
        <Row className="justify-content-between align-items-center">
          <Col md={7}>
            <SectionHeader
              icon={enrolIcon}
              title={PageHeader.Title}
              highlight={PageHeader.TitleHighlight}
              text={PageHeader.Content}
            />
          </Col>
          <Col md={4}>
            <img src={PageHeader.Image?.childImageSharp.fixed.src} />
          </Col>
        </Row>
      </Container>

      <Container className="publications container">
        <Row style={{ alignItems: "center" }}>
          <Col md={12}>
            <SectionHeader
              icon={enrolIcon}
              title={oraclePublications.title}
              highlight={oraclePublications.titlehighlight}
              text={oraclePublications.text}
            />
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row>
          {oraclePublications.oraclerersources.map((data) => (
            <Col md={3} className="mb-5">
              <div
                className="our-purpose__card"
                style={{ margin: 0, height: "400px" }}
              >
                <OracleTiles
                  title={data.title}
                  text={data.text}
                  icon={data.image.publicURL}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="oracle container mt-5">
        <Row className="justify-content-between align-items-center">
          <Col md={4}>
            <img src={industrySpecilizatiion.Image.childImageSharp.fixed.src} />
          </Col>
          <Col md={7}>
            <SectionHeader
              icon={enrolIcon}
              title={industrySpecilizatiion.Title}
              highlight={industrySpecilizatiion.TitleHighlight}
              text={industrySpecilizatiion.Content}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export const pageQuery = graphql`
  query {
    allStrapiOraclePage {
      nodes {
        oracleTopBanner {
          publicURL
        }
        PageTitle
        industrySpecilizatiion {
          Content
          Title
          TitleHighlight
          Image {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        PageHeader {
          Content
          Title
          TitleHighlight
          Image {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }

        oraclePublications {
          title
          titlehighlight
          text
          oraclerersources {
            id
            text
            title
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`;
export default Publications;
