import React from "react";
import sectionHeaderStyles from "./publication.module.scss";
import styles from "./oracle.scss";
import ReactMarkdown from "react-markdown";

const OracleTiles = (props) => {
  return (
    <div
      className={`${sectionHeaderStyles.sectionHeader} oracleTiles`}
      style={{ textAlign: "center" }}
    >
      <img src={props.icon} className="img-fluid"></img>
      <h4 className="mt-4 mb-4">
        <span className="highlight">{props.title}</span>
      </h4>
      <ReactMarkdown source={props.text} />
    </div>
  );
};

export default OracleTiles;
